export const ShopItems = [
  {
    url: "/shop-details",
    src: "/img/shop/shop_img01.png",
    tag: "Plumbing",
    title: "Biovert Cleaner",
    price: 19.99,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img02.png",
    tag: "Plumbing",
    title: "Magic Spin Mop",
    price: 16,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img03.png",
    tag: "Plumbing",
    title: "Biovert Cleaner",
    price: 32,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img04.png",
    tag: "Plumbing",
    title: "Clarke Machines",
    price: 28.5,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img05.png",
    tag: "Plumbing",
    title: "Clarke CFP Floor",
    price: 46,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img06.png",
    tag: "Plumbing",
    title: "Cillit Bang",
    price: 12,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img07.png",
    tag: "Plumbing",
    title: "Sensitive Skin",
    price: 14,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img08.png",
    tag: "Plumbing",
    title: "Clean Energy",
    price: 47.99,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img09.png",
    tag: "Plumbing",
    title: "Biovert Cleaner",
    price: 19.99,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img10.png",
    tag: "Plumbing",
    title: "Magic Spin Mop",
    price: 16,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img11.png",
    tag: "Plumbing",
    title: "Biovert Cleaner",
    price: 32,
  },
  {
    url: "/shop-details",
    src: "/img/shop/shop_img12.png",
    tag: "Plumbing",
    title: "Clarke Machines",
    price: 28.5,
  },
];
